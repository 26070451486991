import React from "react";
import { Layout } from "../components/Layout/Layout";
import { PageTitle } from "../components/PageTitle/PageTitle";
import { ProductsPageQuery } from "../../graphql-types";
import { SEO } from "../components/Seo";
import { Subtitle } from "../components/Subtitle/Subtitle";
import { SubtitleImage } from "../components/Image/SubtiteImage";
import { graphql } from "gatsby";
import { usePageContext } from "../components/Context/pageContext";
import * as styles from "../styles/pages/products.module.scss";
import { ProductPreview } from "../components/Product/ProductPreview";

interface Props {
  data: any;
}

const Products: React.FC<Props> = ({ data }) => {
  const { lang } = usePageContext();
  const { page, products } = data;

  return (
    <Layout>
      <SEO rawMeta={page?._rawMetadata} />
      <PageTitle content={page?.content?._rawTitle[lang]} />
      <SubtitleImage
        image={page?.content?.image?.asset?.gatsbyImageData}
        alt={"concave"}
      />
      <Subtitle content={page?.content?._rawSubtitle[lang]} />
      <div className={styles.mosaic}>
        {page?.content?.productList?.map((e: any, index: number) => {
          return (
            <ProductPreview
              key={`product-preview${index}`}
              image={e?.images?.[0]?.image?.asset?.gatsbyImageData as string}
              slug={e?.slug?.current as string}
              title={e?._rawTitle?.[lang] as string}
              price={e?.defaultVariant?.price as string}
              alt={(e?.images?.[0]?._rawAlt?.[lang] as string) || "concave"}
            />
          );
        })}
      </div>
    </Layout>
  );
};

export default Products;

export const QUERY = graphql`
  query ProductsPage {
    page: sanityAllProductsPage {
      content {
        _rawSubtitle
        _rawTitle
        image {
          asset {
            gatsbyImageData
          }
        }
        productList {
          slug {
            current
          }
          _rawTitle
          defaultVariant {
            price
          }
          variants {
            price
          }
          images {
            _rawAlt
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
      _rawMetadata(resolveReferences: { maxDepth: 3 })
    }
  }
`;
