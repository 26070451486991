import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { usePageContext } from "../Context/pageContext";
import * as styles from "./productPreview.module.scss";
import { StyledLink } from "../StyledLink/StyledLink";

interface Props {
  slug: string;
  title: string;
  price: any;
  image: any;
  alt: string;
}

export const ProductPreview = React.forwardRef<HTMLDivElement, Props>(
  ({ slug, title, price, image, alt }, ref) => {
    return (
      <div ref={ref}>
        <StyledLink to={`/products/${slug}`} className={styles.wrapper}>
          <div className={styles.card}>
            {image && (
              <GatsbyImage
                image={image}
                alt={alt || ""}
                className={styles.vignette}
              />
            )}
          </div>
          <div className={styles.title}>{title}</div>
          <div className={styles.price}>{price}€</div>
        </StyledLink>
      </div>
    );
  }
);
