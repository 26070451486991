import { Link } from "gatsby";
import React from "react";
import { usePageContext } from "../Context/pageContext";
import * as styles from "./StyledLink.module.scss";

export interface StyledLinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
}

export const StyledLink: React.FC<StyledLinkProps> = ({
  to,
  children,
  className,
}) => {
  const { lang, defaultLang } = usePageContext();
  const isExternal = to.indexOf("http") !== -1 ? true : false;
  const path = `${lang !== defaultLang ? `/${lang}${to}` : to}`;

  return (
    <>
      {isExternal ? (
        <a href={to}>{children}</a>
      ) : (
        <Link className={`${styles.link} ${className}`} to={path}>
          {children}
        </Link>
      )}
    </>
  );
};
